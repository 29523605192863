<template>
  <div class="main">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="box center mB20  box-bottom-border" style="padding-bottom: 20px;">
        <!-- <el-form-item label="分析时段" prop="period">
          <el-select @change="clearTable0" v-model="ruleForm.period">
            <el-option v-for="item in periodList" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- class="ml24" -->
        <el-form-item prop="startTime" label="分析时段">
          <el-date-picker placeholder="请选择时间" type="datetime" @change="clearTable" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.startTime"> </el-date-picker>
        </el-form-item>
        <span class="line">-</span>
        <el-form-item prop="endTime">
          <el-date-picker @change="clearTable" placeholder="请选择时间" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.endTime"> </el-date-picker>
        </el-form-item>
        <!--        <el-form-item class="ml24" prop="selTime">
          <el-date-picker
              placeholder="请选择时间"
              type="date"
              :clearable="false"
              value-format="yyyy-MM-dd"
              @change="getEndTime"
              v-model="ruleForm.selTime">
          </el-date-picker>
        </el-form-item>
        <el-form-item class="ml24" label="范围" prop="period">
          <el-select @change="getEndTime" v-model="ruleForm.range">
            <el-option
                v-for="item in rangeList"
                :key="item.name"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <el-button @click="querryData" class="mL44 dataQuery ">查询</el-button>
      </div>
      <div class="box center mB20">
        <el-form-item label="监测类型" prop="type" style="margin-right: 44px;">
          <el-select @change="changeType" v-model="ruleForm.type">
            <el-option v-for="item in typeList" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="quotaList.length > 0" label="监测指标" prop="quota">
          <el-radio-group @change="clearTable" v-model="ruleForm.quota">
            <el-radio v-for="item of quotaList" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--        <div v-if="ruleForm.period === 2" class="box center ml24 smallLabel">-->
        <div v-if="false" class="box center ml24 smallLabel">
          <span class="el-form-item__label wid96">参数录入</span>
          <el-form-item label="采样频率" prop="samplingFrequency">
            <el-input v-model="ruleForm.samplingFrequency" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item class="ml24" label="分段长度" prop="fractionLength">
            <el-input v-model="ruleForm.fractionLength" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
      </div>

    </el-form>
    <template v-if="tableData.length">
      <div class="box-bottom-border" style="padding-bottom: 20px;">
      <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-table-column align="center" prop="target.code" label="测点编号"></el-table-column>
        <el-table-column align="center" prop="target.site" label="位置描述">
          <template slot-scope="scope">{{ scope.row.target.site || "—" }}</template>
        </el-table-column>
        <el-table-column align="center" prop="timeDuration" label="时段长度">
          <!-- <template slot-scope="scope">{{ scope.row.tempEffectDuration === 0 ? scope.row.tempEffectDurationOther : scope.row.tempEffectDuration | timeDuration }}</template> -->
          <template slot-scope="scope">{{ scope.row.tempEffectDuration === 0 ? scope.row.tempEffectDurationOther : scope.row.tempEffectDuration | timeDurationZhw }}</template>
        </el-table-column>
        <el-table-column align="center" prop="timePeriod" label="时间间隔(s)">
          <template slot-scope="scope">{{ scope.row.tempEffectPeriod || "—" }}</template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="操作">
          <template slot-scope="scope">
            <span @click="look(scope.row.target)" class="pointer operate-txt">查看数据</span>
          </template>
        </el-table-column>
        <template slot="empty">
          <span class="iconfont iconzanwushuju-"></span>
          <span>暂无数据</span>
        </template>
      </el-table>
      <common-pagination class="pageMain" :hiddenTotal="hiddenTotal" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    </template>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>

    <div v-show="showMain" class="chartMain">
      <div class="fs16 box center mT20 mB20">
        <img style="margin-right: 12px;width: 14px;height: 14px;" src="@/assets/images/fragment-title-icon.png" />
        <div style="margin-right: 20px;">监测数据温度效应提取结果</div>
      </div>
      <div class="newBox">
        <div class="title">作用效应</div>
        <div id="myChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import moment from "moment";
import CommonPagination from "../../../components/Pagination";
let option = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 40,
      end: 60,
      bottom: 10,
      textStyle: {
        color: "#58637f" //滚动条两边字体样式
      },
      fillerColor: "rgba(39, 114, 240, 0.2)", //选中范围的填充颜色
      handleColor: "#194fdb", //滑块两端的线
      borderColor: "rgba(255,255,255,0.00)" //边框
      // yAxisIndex: [0],
      // filterMode: "empty"
    },
    {
      type: "inside",
      realtime: true,
      start: 40,
      end: 60
    }
  ],
  tooltip: {
    show: true,
    trigger: "axis"
  },
  grid: {
    top: "30",
    left: "80",
    right: "8",
    bottom: "60"
  },
  xAxis: {
    type: "category",
    axisLabel: {
      color: "#fff",
      fontSize: 14,
      formatter: function (value) {
        return value.split(" ").join("\n");
      },
      showMinLabel: false,
      showMaxLabel: false,
      rotate: 6
      //   color: "#cdd0d8",
      //   // interval: function(value){
      //   //   return Math.ceil(value.length / 6)
      //   // },
      //   fontSize: 12,
      // //   rotate: 4,
      //   showMinLabel: true,
      //   showMaxLabel: true,
      // //   showMinLabel: false,
      //   formatter:function(value){
      //     return value.split(" ").join("\n");
      // }
    },
    axisLine: {
      lineStyle: {
        color: "#1c2b51"
        // type: "dashed"
      }
    },
    axisTick: {
      show: false
    },
    data: []
  },
  yAxis: [
    {
      type: "value",
      name: "作用效应",
      nameTextStyle: {
        color: "#58637f",
        fontSize: 14
      },
      axisLabel: {
        color: "#58637f",
        fontSize: 14
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      splitLine: {
        lineStyle: {
          color: "#1c2b51",
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      type: "value",
      axisLine: {
        show: false,
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      axisTick: {
        show: false
      }
    }
  ],
  series: [
    {
      name: "原始数据",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#6DB5C7"
      },
      lineStyle: {
        width: 1
      }
    },
    {
      name: "拟合结果",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#7CDD87"
      },
      lineStyle: {
        width: 2
      }
    },
    {
      name: "拟合区间下限",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#FF7575"
      },
      lineStyle: {
        width: 1
      }
    },
    {
      name: "拟合区间上限",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#FF7575"
      },
      lineStyle: {
        width: 1
      }
    }
  ]
};
import mixin from "./mixin";

export default {
  name: "temAnalysis",
  components: {
    CommonPagination
  },
  mixins: [mixin],
  data() {
    return {
      hiddenTotal: true,
      tableData: [],
      pageSize: 5,
      currentPage: 1,
      total: 0,
      loading: false,
      ruleForm: { period: 1, startTime: "", endTime: "", quota: "", type: "" },
      periodList: [
        { name: "历史查询", id: 1 }
        // {name:'重新计算',id:2}
      ],
      typeList: [],
      quotaList: [],
      // rangeList:[{name:'全天',id:1},{name:'0点-6点',id:2},{name:'6点-12点',id:3},{name:'12点-18点',id:4},{name:'18点-24点',id:5}],
      myChart: {},
      size: 5000,
      showMain: false,
      loadingPage: null,
      startTime: "",
      endTime: "",
      targetUnit: "",
      targetName: "",
      targetId: "",
      dataType: 11
    };
  },
  mounted() {
    this.changeTimeDay(30);
    // this.ruleForm.endTime = new Date().Format('yyyy-MM-dd hh:mm')
    // this.ruleForm.startTime = new Date(new Date().getTime() - (6 * 60 * 60 * 1000)).Format('yyyy-MM-dd hh:mm')
    this.getTypeList(); // 请求监测类型
  },
  methods: {
    changeTimeDay(n) {
      var day = new Date();
      var day1 = day.getTime(); //获得当前时间戳
      var day2 = day1 - n * 24 * 60 * 60 * 1000;
      var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      var end = moment(day).format("YYYY-MM-DD HH:mm:ss");

      this.ruleForm.startTime = start;
      this.ruleForm.endTime = end;
    },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    clearTable0() {
      if (this.ruleForm.period == 1) {
        this.changeTimeDay(30);
      }
      if (this.ruleForm.period == 2) {
        this.changeTimeDay(1);
      }
    },

    clearTable() {
      this.showMain = false;
      this.tableData = [];
      this.querryData()
    },
    getTime() {
      let endTime = new Date(this.ruleForm.endTime).getTime() / 1000 / 60;
      let startTime = new Date(this.ruleForm.startTime).getTime() / 1000 / 60;
      let cha = (endTime - startTime) / 60 / 24;
      if (cha < 0) {
        this.$message({
          message: "开始时间应早于结束时间!",
          type: "warning"
        });
        return false;
      } else if (cha > 365) {
        this.$message({
          message: "查询时间间隔应小于365天!",
          type: "warning"
        });
        return false;
      }
      return true;
    },
    changeType() {
      this.clearTable();
      // this.getQuotaList()
      this.getQuotaTypeList();
    },
    querryData() {
      this.showMain = false;
      if (!this.getTime()) return;
      this.loading = true;
      this.handleCurrentChange(1);
    },
    initEcharts() {
      this.myChart = echarts.init(document.getElementById("myChart"));
      if (this.targetUnit == "") {
        option.yAxis[0].name = "作用效应";
      } else {
        option.yAxis[0].name = "作用效应" + "(" + this.targetUnit + ")";
      }
      this.myChart.setOption(option);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    look(target) {
      console.log("target", target);
      this.targetId = target.id;
      this.targetName = target.code;
      this.targetUnit = target.unit;
      console.log(this.targetUnit);
      this.showMain = true;
      if (this.ruleForm.period === 1) {
        this.getLineData("history");
      } else {
        this.getLineData("hisData");
      }
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getTable();
    },
    getTypeList() {
      // this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
      let params = {
        dataType: this.dataType,
        projectId: sessionStorage.getItem("projectId")
      };

      this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.typeList = res.data;
          this.ruleForm.type = res.data[0].id;
          // this.getQuotaList()
          this.getQuotaTypeList();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getQuotaList() {
      let params = {
        typeGroupId: this.ruleForm.type
      };
      this.$axios.get(`${this.baseURL}base/baseTargetType/byGroupId`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.quotaList = res.data;
          this.ruleForm.quota = res.data[0].id;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getTable() {
      let params = {
        projectId: this.$store.state.projectId,
        type: this.ruleForm.quota,
        current: this.currentPage,
        size: this.pageSize
      };
      // /setttings/settingsDataAnalysis/tem/
      // this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/type/${this.currentPage}/${this.pageSize}`, { params }).then((res) => {
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/tem/${this.currentPage}/${this.pageSize}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        console.log("获取数据分析列表--------", res);
        if (res.errCode === "200") {
          this.tableData = data.records;
          if (this.tableData.length) {
            this.look(this.tableData[0].target);
          }
          this.loading = false;
          this.total = data.total;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getLineData(url) {
      // 请求折线图
      let params = {
        targetId: this.targetId,
        startTime: this.ruleForm.startTime + ":00",
        endTime: this.ruleForm.endTime + ":00"
      };
      if (this.ruleForm.period === 1) {
        params.size = this.size;
        params.sort = 0;
      }
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$axios.get(`${this.baseURL}data/${url}/tem/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        console.log(res);
        let data = res.data;
        let xData = [];
        let yData = [];
        let yData2 = [];
        let yData3 = [];
        let yData4 = [];
        if (res.errCode === "200") {
          if (this.ruleForm.period === 1) {
            option.grid.bottom = "60";
            for (let item of data) {
              // xData.push(new Date(item.time).Format('hh:mm:ss'))
              xData.push(moment(item.time).format("hh:mm:ss"));
              yData.push(item.data[0]);
              yData2.push(item.data[1]);
              yData3.push(item.data[2]);
              yData4.push(item.data[3]);
            }
          } else {
            option.grid.bottom = "90";
            xData = data.time;
            yData = data.number;
          }

          option.xAxis.data = xData;
          option.series[1].data = yData;
          option.series[2].data = yData2;
          option.series[3].data = yData3;
          option.series[0].data = yData4;
          let max = Math.max.apply(null, yData);
          let min = Math.min.apply(null, yData);
          let max2 = Math.max.apply(null, yData2);
          let min2 = Math.min.apply(null, yData2);
          let max3 = Math.max.apply(null, yData3);
          let min3 = Math.min.apply(null, yData3);
          let max4 = Math.max.apply(null, yData4);
          let min4 = Math.min.apply(null, yData4);
          option.yAxis[0].max = Math.max.apply(null, [max, max2, max3, max4]).toFixed(3);
          option.yAxis[0].min = Math.min.apply(null, [min, min2, min3, min4]).toFixed(3);
          this.initEcharts();
          this.loadingPage.close();
        } else {
          this.$message.error(res.errMsg);
          this.loadingPage.close();
        }
      });
    },
    getCleanData() {
      // 请求基础数据 去掉
      let params = {
        targetId: this.targetId,
        sort: 0,
        startTime: this.startTime,
        endTime: this.endTime,
        size: this.size
      };
      this.$axios.get(`${this.baseURL}data/history/raw/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        console.log(res);
        let data = res.data;
        let xData = [];
        let yData = [];
        if (res.errCode === "200") {
          for (let item of data) {
            xData.push(new Date(item.time).Format("hh:mm:ss"));
            yData.push(item.data[0]);
          }
          let max = Math.max.apply(null, yData).toFixed(3);
          let min = Math.min.apply(null, yData).toFixed(3);
          option.yAxis[0].max = max;
          option.yAxis[0].min = min;
          option.series[0].data = yData;
          if (!option.xAxis.data || option.xAxis.data.length === 0) option.xAxis.data = xData;
          this.loadingPage.close();
          this.initEcharts();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  ::v-deep .el-form {
    margin-top: 24px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 96px;
      padding: 0;
    }

    .smallLabel {
      .el-form-item__label {
        width: 80px;
        font-weight: 300;

        &.wid96 {
          width: 96px;
        }
      }
    }

    .el-form-item {
      margin-bottom: 0;
      display: flex;

      &.mB20 {
        margin-bottom: 20px;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }
    }

    .line {
      padding: 0 10px;
    }

    .el-input {
      width: 134px;
      height: 30px;

      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }

      .el-select__caret {
        line-height: 30px;
      }

      &.el-date-editor {
        .el-input__inner {
          padding: 0 0 0 8px;
        }

        .el-icon-date {
          display: none;
        }
      }
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 180px;
    }

    .el-date-editor .el-input__icon {
      display: none;
    }
  }

  ::v-deep .el-radio-group {
    .el-radio__inner {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border: 1px solid #787878;

      &:after {
        width: 6px;
        height: 6px;
      }
    }

    .el-radio__label {
      color: #fff;
      font-size: 16px;
    }
  }

  .pageMain {
    margin-top: 20px;
  }

  .chartMain {
    position: relative;
  }

  #myChart {
    width: 100%;
    //height: 240px;
    height: 270px;
    overflow: hidden;
  }
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}

.el-radio{
  margin-bottom: 0;
}
.title {
  position: relative;
  left: 14px;
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
}
.title::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -12px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
</style>
